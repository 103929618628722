import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ActionLink from "../components/action-link"


const GettingStartedPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Word stucadoor" />
    <h1>Aanmelden als stucadoor</h1>
    <p>Wil je ook aan de slag als stucadoor? Neem dan gerust contact met ons op en wij vertellen je meer. Eerst wat meer weten over de opleiding? Dat kan ook:</p>
    <ActionLink to="/about-us" >Lees meer over de opleiding ›</ActionLink>

    <h3>Telefoon</h3>
    <ul className="mb-4">
      <li><a href={`tel:${data.contact.phone.data}`}>{data.contact.phone.visual}</a></li>
    </ul>

    <h3>Mobiel</h3>
    <ul className="mb-4">
      <li><a href={`tel:${data.contact.peet.data}`}>Peet: {data.contact.peet.visual}</a></li>
      <li><a href={`tel:${data.contact.michel.data}`}>Michel: {data.contact.michel.visual}</a></li>
    </ul>

    <h3>Email</h3>
    <ul className="mb-4">
      <li><a href={`mailto:${data.contact.email}`}>{data.contact.email}</a></li>
    </ul>
    
  </Layout>
)

export default GettingStartedPage


export const query =  graphql`
{
  contact: dataYaml {
    coordinates
    zoom
    street
    zipcode
    city
    email
    address_name
    michel {
      visual
      data
    }
    peet {
      visual
      data
    }
    phone {
      visual
      data
    }
  }
}
`