import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ActionLink = ({to, children}) => {
  return <div><Link to={to} className={`text-traub-green font-bold no-underline`}>{children}</Link></div>
}

ActionLink.propTypes = {
  to: PropTypes.string.isRequired,
}

ActionLink.defaultProps = {
  to: "/",
}

export default ActionLink
